import React, { Suspense, useEffect } from "react";
import './App.css';
import './MobileApp.css';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useAuth } from "./context/ContextData";
import UseWindowSize from "./components/UseWindowSize";

const Footer = React.lazy(() => import('./desktop/components/Footer'));
const DemoFooter = React.lazy(() => import('./desktop/components/DemoFooter'));
const Header = React.lazy(() => import('./desktop/components/Header'));
const Home = React.lazy(() => import('./desktop/pages/Home'));
const ProductTabs = React.lazy(() => import('./desktop/pages/ProductTabs'));
const ProductDetails = React.lazy(() => import('./desktop/pages/ProductDetails'));
const OrderDetails = React.lazy(() => import('./desktop/pages/OrderDetails'));
const Orders = React.lazy(() => import("./desktop/pages/Orders"));

// // --------------------------------Mobile Routes --------------------------------
const MobHeader = React.lazy(() => import("./mobile/components/MobHeader"));
const MobFooter = React.lazy(() => import("./mobile/components/MobFooter"));
const MobDemoFooter = React.lazy(() => import("./mobile/components/MobDemoFooter"));
const MobHome = React.lazy(() => import("./mobile/pages/MobHome"));
const MobProductTab = React.lazy(() => import("./mobile/pages/MobProductTab"));
const MobProductDetails = React.lazy(() => import("./mobile/pages/MobProductDetails"));
const MobOrderDetails = React.lazy(() => import("./mobile/pages/MobOrderDetails"));
const MobOrders = React.lazy(() => import("./mobile/pages/MobOrders"));
const MobCategories = React.lazy(() => import("./mobile/components/MobCategories"));
const MobSearch = React.lazy(() => import("./mobile/components/MobSearch"));

// // --------------------------------Common Routes --------------------------------
const DealerAbout = React.lazy(() => import("./components/DealerAbout"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
const PrivacyPolicies = React.lazy(() => import("./components/pages/PrivacyPolicies"));
const AllJobs = React.lazy(() => import("./components/careers/AllJobs"));
const JobDetail = React.lazy(() => import("./components/careers/JobDetail"));


function App() {
  // const innerWidth = window.innerWidth
  const { demo, setDealerId, setProducts } = useAuth();
  global.duUrl = "https://dealer.wallicon.in/"

  const { width } = UseWindowSize()
  const getDealerId = localStorage.getItem("dealerId")
  useEffect(() => {
    if (getDealerId) {
      setDealerId(getDealerId)
    }
  }, [getDealerId, setDealerId])


  useEffect(() => {
    const getSTC = () => {
      var myHeaders = new Headers();
      myHeaders.append("wu-platform", process.env.REACT_APP_USER_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "customer/landing", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setProducts(result.data.products);
          }
        })
        .catch(error => console.log('error', error));
    }
    getSTC()
  }, [setProducts])
  return (
    <React.Fragment>
      <Suspense fallback={<div className="App-header">
        <img src="/assets/icons/wallicon.gif" alt="loader" />
      </div>}>

        {width > 600 ?
          <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:any" element={<ProductTabs />} />
              <Route path="/:any/:any" element={<ProductTabs />} />
              <Route path="/:any/:any/:any" element={<ProductTabs />} />
              <Route path="/productdetails/:any/:id" element={<ProductDetails />} />
              <Route path="/checkout/cart" element={<OrderDetails />} />
              <Route path="/order" element={<Orders />} />
              <Route path="/about" element={<DealerAbout />} />
              <Route path="/careers" element={<AllJobs />} />
              <Route path="/careers/:any/:any" element={<JobDetail />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<PrivacyPolicies />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
            <Footer />
            {!demo ? "" : <DemoFooter />}
          </BrowserRouter>
          :
          <BrowserRouter>
            <ScrollToTop />
            <MobHeader />
            <Routes>
              <Route path="/" element={<MobHome />} />
              <Route path="/:any" element={<MobProductTab />} />
              <Route path="/:any/:any" element={<MobProductTab />} />
              <Route path="/:any/:any/:any" element={<MobProductTab />} />
              <Route path="/productdetails/:any/:id" element={<MobProductDetails />} />
              <Route path="/checkout/cart" element={<MobOrderDetails />} />
              <Route path="/order" element={<MobOrders />} />
              <Route path="/about" element={<DealerAbout />} />
              <Route path="/careers" element={<AllJobs />} />
              <Route path="/careers/:any/:any" element={<JobDetail />} />
              <Route path="/categories" element={<MobCategories />} />
              <Route path="/search" element={<MobSearch />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<PrivacyPolicies />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
            {!demo ? "" : <MobDemoFooter />}
            <MobFooter />
          </BrowserRouter>
        }
      </Suspense>
    </React.Fragment>
  );
}

export default App;

function NoMatch() {
  return (
    <div className="text-center mt-5">
      <h1 className="fs_100 text-danger cpm">404</h1>
      <h1 className="fs_80 cp">Oops! Page Not Found</h1>
    </div>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}