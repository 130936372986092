import React, { createContext, useState } from "react";

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

const AuthContext = createContext({});

const AuthProvider = (props) => {

    const [products, setProducts] = useState([]);
    const [styles, setStyles] = useState([]);
    const [cart, setCart] = useState([]);
    const [wished, setWished] = useState([])
    const [removeWished, setRemoveWished] = useState();
    const [removeCart, setRemoveCart] = useState();

    const [basicInfo, setBasicInfo] = useState("");
    const [getDealerId, setGetDealerId] = useState("");
    const [demo, setDemo] = useState("");
    const [dealerId, setDealerId] = useState("");
    const [sid, setSid] = useState("");

    const authContextValue = {
        cart, setCart,
        wished, setWished,
        removeWished, setRemoveWished,
        removeCart, setRemoveCart,
        basicInfo, setBasicInfo,
        getDealerId, setGetDealerId,
        demo, setDemo,
        products, setProducts,
        dealerId, setDealerId,
        sid, setSid,
        styles, setStyles
    };

    return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
